import React from "react"
import styled from "styled-components"
import { theme } from '../../config/website'
import Header from "./header"
import classNames from "classnames"
import Transition from "../components/transition"
import { StickyContainer, Sticky } from 'react-sticky';

import BariolBold from '../fonts/Bariol/Bariol_Bold.otf';
import BariolRegular from '../fonts/Bariol/Bariol_Regular.otf';
import BariolRegularItalic from '../fonts/Bariol/Bariol_Regular_Italic.otf';

const Wrapper = styled.div`

  -webkit-tap-highlight-color: rgba(0,0,0,0);

  @font-face {
    font-family: 'Bariol-Bold';
    src: url('${BariolBold}') format('opentype');
  }

  @font-face {
    font-family: 'Bariol';
    src: url('${BariolRegular}') format('opentype');
  }

  @font-face {
    font-family: 'BariolRegular-Italic';
    src: url('${BariolRegularItalic}') format('opentype');
  }

  min-width: 320px;
  margin: 0 auto;
  font-family: "Bariol";
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  font-smooth: never;
  -webkit-font-smoothing: antialiased;
  color: ${theme.primaryColor};

  .sticky {
    z-index: 10;

    &.sticky-active {
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.07);
    }
  }

  main > .IndexContainer {
    padding-bottom: 0;
  }

  @media (min-width: 1024px) {
      header {
        padding: 32px 64px;
      }
      main > div {
        padding: 0 64px 64px 64px;
      }
  }

  @media (min-width: 700px) and (max-width: 1024px) {
      header {
          padding: 28px 48px;
      }
      main > div {
        padding: 0 48px 48px 48px;
      }
  }

  @media (max-width: 700px) {
    header {
        padding: 28px 24px;
    } 
    main > div {
        padding: 0 24px 24px 24px;
    }
  }

  h1 {
      font-size: 26px;
      font-weight: normal;
  }

  strong {
      font-weight: normal;
      font-family: Bariol-Bold;
  }

  h2 {
      font-size: 24px;
      font-weight: normal;
  }

  h3 {
      font-size: 16px;
  }
`

const Content = styled.main`
    font-size: 14px;

    p, ul, li, pre {
        line-height: 18px;
        letter-spacing: 0.5px;
    }
`

const StickySpacer = styled.div`
    height: 1px;
`

export default ({ children, location }) => (
    <Wrapper>
        <StickySpacer />
        <StickyContainer>
            <Sticky>
                {({ style, isSticky }) => {
                    const className = classNames("sticky", {
                        "sticky-active": isSticky
                    })
                    return (
                        <div className={className} style={style}>
                            <Header location={location}/>
                        </div>
                    )
                }}
            </Sticky>
            <Transition location={location}>
                <Content>{children}</Content>
            </Transition>
        </StickyContainer>
    </Wrapper>
);