import React from "react";
import { Link } from "gatsby";
import styled from "styled-components"
import { theme } from '../../config/website'

const Container = styled.header`
    overflow: hidden;
    background-color: white;
`

const Heading = styled.h3`
    float: left;
    margin: 0;
    height: 24px;
    line-height: 24px;
`

const SiteHeading = styled.h2`
    float: left;
    margin: 0;
    height: 24px;
    line-height: 24px;
`

const SiteHeadingLink = styled(Link)`
    font-family: 'Bariol-Bold';
    cursor: pointer;
    text-decoration: none;
    color: ${theme.primaryColor};

    :hover, :active, :visited {
        text-decoration: none;
        color: inherit;
    }
`

const HeadingLink = styled(Link)`
    padding: 12px;
    cursor: pointer;
    text-decoration: none;
    color: ${theme.primaryColor};
    font-family: 'Bariol-Bold';
    letter-spacing: 1px;

    :hover, :active {
        text-decoration: underline;
        color: inherit;
    }

    :visited {
        color: inherit;
    }

    :not(.active) {
        color: ${theme.secondaryColor};
        font-family: 'Bariol';
        font-weight: normal;
    }
`

const NavigationBar = styled.nav`
    float: right;
    margin-right: -12px;

    >::before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
`

const Header = ({ location }) => {
    const inactiveClassName = ""
    const activeClassName = "active"
    const isAboutActive = location.pathname.includes("about")

    return (
        <Container>
            <SiteHeading>
                <SiteHeadingLink to="/">
                    Ashley Arbour
                </SiteHeadingLink>
            </SiteHeading>
            <NavigationBar>
                <Heading>
                    <HeadingLink to="/" className={isAboutActive ? inactiveClassName : activeClassName}>
                        Work
                    </HeadingLink>
                </Heading>
                <Heading>
                    <HeadingLink to="/about" className={isAboutActive ? activeClassName : inactiveClassName}>
                        About
                    </HeadingLink>
                </Heading>
            </NavigationBar>
        </Container>
    )
}

export default Header