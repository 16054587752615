module.exports = [{
      plugin: require('/Users/alexanderhughes/Development/Web/Gatsby/Prismic/ashley-portfolio/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/Users/alexanderhughes/Development/Web/Gatsby/Prismic/ashley-portfolio/src/components/layout.js"},
    },{
      plugin: require('/Users/alexanderhughes/Development/Web/Gatsby/Prismic/ashley-portfolio/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/alexanderhughes/Development/Web/Gatsby/Prismic/ashley-portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
