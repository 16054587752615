import './src/styles/global.css'

const transitionDelay = 250;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  return false;
};