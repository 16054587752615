module.exports = {
  pathPrefix: '/',
  siteTitle: 'Ashley Arbour',
  siteTitleAlt: 'AshleyArbour',
  siteUrl: 'https://ashleyarbour.ca',
  siteLanguage: 'en',
  siteLogo: '/icons/icon-512x512.png',
  siteDescription: 'Portfolio for Ashley Abour',
  siteEmail: 'ashley.arbour@gmail.com',
  theme: {
    primaryColor: '#1d1c27',
    secondaryColor: '#8e8d93',
    manifest: {
      foregroundColor: '#1d1c27',
      backgroundColor: '#8e8d93'
    }
  }
};